<template>
  <div class="promotion-sticker" v-show="show" v-if="false">
    <a tagname="a" target="_blank" class="">
      <i class="el-icon-error icon-close" @click="hanleClick"></i>
      <img src="https://stc_cdn.vcdn.vn/mainsite/images/NPUGE-T4-268x268.jpg" />
    </a>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      show: true,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    hanleClick() {
      this.show = false;
      this.$emit("show", this.show);
    },
  },
};
</script>

<style scoped lang="scss">
.promotion-sticker .changed .icon-close,
.promotion-sticker .changed img {
  -webkit-animation: slide 1s;
  animation: slide 1s;
}

.promotion-sticker > div,
.promotion-sticker a {
  -webkit-animation: fadeToLeft 1s 1;
  animation: fadeToLeft 1s 1;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.promotion-sticker > div.hide,
.promotion-sticker a.hide {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  z-index: -1;
  -webkit-transform: translateX(999px);
  transform: translateX(999px);
  -webkit-transition: z-index 1s, -webkit-transform 1s;
  transition: z-index 1s, -webkit-transform 1s;
  transition: transform 1s, z-index 1s;
  transition: transform 1s, z-index 1s, -webkit-transform 1s;
}

.promotion-sticker > div img,
.promotion-sticker a img {
  width: 120px;
  border-radius: 4px 0 0 4px;
}

.promotion-sticker > div i,
.promotion-sticker a i {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  font-size: 26px;
}

.promotion-sticker > div i:before,
.promotion-sticker a i:before {
  content: "";
  background: #fff;
  height: 10px;
  width: 10px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.promotion-sticker > div i:after,
.promotion-sticker a i:after {
  content: "";
  color: #d3dce6;
  position: relative;
}

@-webkit-keyframes fadeToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
</style>
