<template>
  <div id="footer" class="footer" v-if="show">
    <div class="lang" v-if="showLang">
      <img class="headerRightImg" src="@/assets/earth.png" alt="" srcset="" />
      <el-select v-model="lang" @change="changeLang" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="footer-content">
      <div class="right">
        <img alt="Games-logo" class="logo" :src="info[$pathName].src" />
        <!-- <p>Công ty Cổ phần</p> -->
      </div>
      <div class="footer_info">
        <p>
          <strong>{{ $t("Footer.address") }}</strong>
          {{ info[$pathName].address }}
        </p>
        <!-- <p>
          <strong>Hỗ trợ:</strong>
          <a href="https://hotro.zing.vn" target="_blank"
            >https://hotro.zing.vn</a
          >
        </p>
        <p><strong>Hotline:</strong> 1900 561 558</p> -->
        <!-- <button @click="changeLang">切换语言</button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    showLang: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: true,
      lang: "EN",
      options: [
        {
          value: "VI",
          label: "Tiếng Việt",
        },
        // {
        //   value: "EN",
        //   label: "EN",
        // },
        {
          value: "TH",
          label: "แบบไทย",
        },
        {
          value: "ID",
          label: "Indonesia",
        },
        {
          value: "EN",
          label: "English",
        },
        {
          value: "ZH",
          label: "中文",
        },
      ],
    };
  },
  computed: {
    info() {
      return {
        mgol: {
          src: require("../assets/mgolLogo.png"),
          address: this.$t("Footer.mgolFooter"),
        },
        mocgame: {
          src: require("../assets/mocLogo.png"),
          address: this.$t("Footer.mocFooter"),
        },
      };
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.show = this.$pathName !== "muugame";
    this.lang = localStorage.getItem("locale") || "EN";
    this.$bus.$on("locale", (content) => {
      this.lang = content;
    });
    // setTimeout(() => {
    //   this.lang = localStorage.getItem("locale") || "EN";
    //   this.$i18n.locale = this.lang;
    //   this.$forceUpdate();
    // }, 100);
  },
  methods: {
    changeLang(e) {
      this.lang = localStorage.getItem("locale") || "EN";
      if (this.lang === "EN") {
        this.lang = e;
        this.$i18n.locale = e;
      } else {
        this.lang = e;
        this.$i18n.locale = e;
      }
      localStorage.setItem("locale", this.lang);
      this.$store.state.lang = this.lang
      // location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  font-size: 14px;
  color: #989898;
  padding: 24px 0;
  border-top: 1px solid #ebebeb;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
  .lang {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #ebebeb;
    img {
      width: 50px;
    }
  }
  p {
    margin: 0;
    line-height: 6.5;
  }
  .fixed {
    bottom: 0;
  }
  .footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 2rem 1rem 0;
  }
  .footer-content.sea {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer-content.sea .logo {
    max-height: 50px;
  }

  .footer-content.sea .right {
    margin-right: unset;
    text-align: center;
  }

  .footer-content .right {
    margin-right: 34px;
    padding-top: 4px;
  }

  .logo {
    // height: 42px;
    max-width: 186px;
    height: auto;
  }
}
@media screen and (max-width: 1000px) {
  .footer .footer-content {
    max-width: 90%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    // flex-direction: column;
    align-items: center;
  }
  .footer .footer-content .right {
    margin-right: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    // margin-bottom: 20px;
  }
  .footer p {
    line-height: 1.5;
  }
  .footer .logo {
    // height: 42px;
    max-width: 100%;
    width: 160px;
    height: 100%;
  }
}
</style>
